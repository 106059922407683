import React from "react"
import Layout from '../components/layout'
import BackgroundImage from "gatsby-background-image"
import '../styles/blog_page.css'
import { graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


export const data = graphql`
query($slug: String!)
 {
  contentfulBlog (slug: {eq:$slug})
  {
    title
    author
    publishedDate(formatString:"MMM Do, YYYY")
    
    featuredImage{
      fluid(maxWidth:1920){
        src
      }
    }
    content {
      json
    }
  }
  }
  `
  const Blog = probs => {
    return (
        <Layout>
                  <BackgroundImage
                      className="Blogpage_background_img"
                      fluid={probs.data.contentfulBlog.featuredImage.fluid}
                  >
                    <div className="blogpage_background_overlay">

                    <Container>
                      <Row>
                            <Col xl={6} lg={6} md={8}>
                                <div className="Blogpage_vertical_line"></div>
                                <h1 className="head_blogpage">{probs.data.contentfulBlog.title}</h1>
                                <p className="blogpage_caption">Sharing different perspectives and experiences about the fastest growing destination for medical tourism.</p>
                                
                             </Col>
                      </Row>
                      
                    </Container>

                    </div>
                    </BackgroundImage>
                {/* <div class="B_btm_svg"></div> */}

                <Container>
                   <div class="B_txt_content">
                     {documentToReactComponents(probs.data.contentfulBlog.content.json)}
                 </div>
                 </Container>

        </Layout>
    )
}
export default Blog;





  

    
